/*
 * @Author: your name
 * @Date: 2021-07-27 16:03:10
 * @LastEditTime: 2021-11-28 10:30:33
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /vue-growing/src/router/index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const router = new VueRouter({
    mode: 'hash',
    //base: process.env.BASE_URL,
    //base: "/teach/", //课程  
    base: "/", //继续教育
    routes: [{
        path: '/',
        name: 'index',
        meta: {
            keepAlive: true
        },
        component: () => import('@/views/index-page.vue'),

    }]
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

export default router