<template>
  <v-app>
    <router-view class="grey lighten-3" style="min-height: 700px;z-index:2;" />
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
